import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'

const maxFileSize = 20;

const dropzoneOptions = {
    parallelUploads: 1,
    maxFiles: 5,
    maxFilesize: maxFileSize,
    dictFileTooBig: 'ファイルサイズは' + maxFileSize + 'MBまでです。',
    dictDefaultMessage: 'ここに画像をドラッグ＆ドロップしてください。',
    dictFallbackMessage: 'ブラウザが対応していません。',
    queuecomplete: function () {
        location.reload()
    },
    error: function error(file, message) {
        alert('[アップロード失敗]\n' + file.name + '\n' + message)
    }
};

var errors = {}
var successes = []

Dropzone.autoDiscover = false

const loadedDropzone = function() {
    let target = document.querySelector('#product_image_dropzone')
    if (target && !target.dropzone) {
        new Dropzone(target, {...dropzoneOptions, ...{paramName: 'product_image[image]'}})
    }
    target = document.querySelector('#funeral_memorial_dropzone')
    if (target && !target.dropzone) {
        new Dropzone(target, {...dropzoneOptions, ...{paramName: 'funeral_memorial[photo]', dictDefaultMessage: 'ここに写真をドラッグ＆ドロップしてください。'}})
    }
    target = document.querySelector('#funeral_movie_dropzone')
    if (target && !target.dropzone) {
        new Dropzone(target, {...dropzoneOptions, ...{paramName: 'funeral_movie[movie]', maxFilesize: 500, dictDefaultMessage: 'ここに動画をドラッグ＆ドロップしてください。'}})
    }
    target = document.querySelector('#oarks_excel_dropzone')
    if (target && !target.dropzone) {
        new Dropzone(target, {
            ...dropzoneOptions,
            ...{
                paramName: 'file',
                dictDefaultMessage: 'ここにエクセルをドラッグ＆ドロップしてください。',
                drop: function() {
                    errors = {}
                    successes = []
                },
                success: function(_file, _response){
                    if (_response.errors !== undefined) {
                        errors[_file.name] = _response.errors
                    } else {
                        successes.push(_file.name)
                    }
                },
                complete: function(file) {
                    this.removeFile(file)
                },
                queuecomplete: function () {
                    for(var success in successes) {
                        $.notify({
                            message: '【' + successes[success] + '】インポートが成功しました'
                        },{
                            element: 'body',
                            type: "info",
                            placement: {
                                from: "top",
                                align: "center"
                            },
                            offset: 20,
                            spacing: 10,
                            z_index: 1031,
                            delay: 2500,
                            timer: 1000,
                            animate: {
                                enter: 'animated fadeInDown',
                                exit: 'animated fadeOutUp'
                            },
                        })
                    }
                    if (errors.length === 0) {
                        alert('インポートが完了しました')
                    } else {
                        for(var name in errors) {
                            var _errors = errors[name]
                            for(var error in _errors) {
                                $.notify({
                                    message: '【' + name + '】' + _errors[error]
                                },{
                                    element: 'body',
                                    position: null,
                                    type: "danger",
                                    placement: {
                                        from: "top",
                                        align: "center"
                                    },
                                    offset: 20,
                                    spacing: 10,
                                    z_index: 1031,
                                    delay: 2500,
                                    timer: 1000,
                                    animate: {
                                        enter: 'animated fadeInDown',
                                        exit: 'animated fadeOutUp'
                                    },
                                })
                            }
                        }
                    }
                }
            }
        })
    }
    target = document.querySelector('#product_set_image_dropzone')
    if (target && !target.dropzone) {
        new Dropzone(target, {...dropzoneOptions, ...{paramName: 'product_set_image[image]'}})
    }
    target = document.querySelector('#catalog_image_dropzone')
    if (target && !target.dropzone) {
        new Dropzone(target, {...dropzoneOptions, ...{maxFiles: 50, paramName: 'catalog_image[image]'}})
    }
}
window.addEventListener('load', loadedDropzone)
