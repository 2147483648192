import $ from 'jquery'
import 'select2/dist/js/select2.min'

$(function () {
    const select2_params = {
        theme: 'bootstrap4',
        dropdownAutoWidth: true,
    }
    $('.js-select2').select2(select2_params)
    $(window).on('resize', function () {
        $('.js-select2').select2(select2_params)
    }).on('cocoon:after-insert', function () {
        $('.js-select2').select2(select2_params)
    }).on('ajax:complete', function () {
        $('.js-select2').select2(select2_params)
    })
})