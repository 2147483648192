import $ from 'jquery'

$(function () {
    $('body').on('change', '.js-search-style-radio', function () {
        let style = $(this).val()
        let target_style
        let condition
        let target_condition
        if (style === 'eq_split_any') {
            target_style = 'cont_split_any'
            condition = 'and'
            target_condition = 'or'
        } else {
            target_style = 'eq_split_any'
            condition = 'or'
            target_condition = 'and'
        }
        $(this).closest('form').find('input[type=text]').each(function () {
            let name = $(this).attr('name')
            if (name.match(target_style)) {
                $(this).attr('name', name.replaceAll('_' + target_condition + '_', '_' + condition + '_').replace('_' + target_style, '_' + style))
            }
        })
    }).on('change', '.js-check-all', function () {
        $(this).closest('form').find('.js-check-target').prop('checked', $(this).prop('checked')).trigger('change');
    })
})