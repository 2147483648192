import $ from 'jquery'
import 'jquery-jpostal-ja'
window.setJPostal = function(postcode_class, prefecture_class, address_class) {
  var options = {
    postcode: [
      postcode_class
    ],
    address: {}
  };
  options["address"][prefecture_class] = '%3';
  options["address"][address_class] = '%4%5%6%7';
  $(postcode_class).jpostal(options);
}
window.addEventListener('load',  function() {
  window.setJPostal('.js-postcode', '.js-prefecture_code', '.js-address_city')
  window.setJPostal('.js-mourner_postcode', '.js-mourner_prefecture_code', '.js-mourner_address_city')
})
